/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import cn from "classnames";
import Imgix from "react-imgix";
import Flicking, { ChangedEvent } from "@egjs/react-flicking";
import { Dots } from "@brainhubeu/react-carousel";
import { useSelector } from "react-redux";
import styles from "./Onboarding.module.scss";
import RoundedButton from "../Buttons/RoundedButton";
import {
  selectCurrentInstagramBasicAccount,
  selectUserInfoLoading,
} from "../../store/user/selectors";
import { InstagramBasicAccounts } from "../../store/user/actions";
import LoadingSmall from "../Loading/LoadingSmall";
import { AccountTypes } from "../../enums/AccountTypes";
import { isMobile } from "../../utils/helpers";

const SwitchCarousel = [
  {
    image:
      "https://imgix.cosmicjs.com/d967f540-4046-11ec-9580-ebf669758fed-HowToSwitch1.png",
    title: "Step 1 Log in to Instagram",
  },
  {
    image:
      "https://imgix.cosmicjs.com/d9689180-4046-11ec-9580-ebf669758fed-HowToSwitch2.png",
    title: "Step 2 Select Settings > Account",
  },
  {
    image:
      "https://imgix.cosmicjs.com/d969ca00-4046-11ec-9580-ebf669758fed-HowToSwitch3.png",
    title: "Step 3 Switch to Business Account",
  },
  {
    image:
      "https://imgix.cosmicjs.com/d9697be0-4046-11ec-9580-ebf669758fed-HowToSwitch4.png",
    title: "Step 4 Confirm Switch",
  },
];

const PersonalCarousel = [
  {
    image:
      "https://imgix.cosmicjs.com/c18458c0-4045-11ec-9580-ebf669758fed-Switch1.png",
    title: "Step 1 Log in to Instagram",
  },
  {
    image:
      "https://imgix.cosmicjs.com/c209dc70-4045-11ec-9580-ebf669758fed-Switch2.png",
    title: "Step 2 Select Settings > Account",
  },
  {
    image:
      "https://imgix.cosmicjs.com/c1d3ff60-4045-11ec-9580-ebf669758fed-Switch3.png",
    title: "Step 3 Switch to Professional Account",
  },
  {
    image:
      "https://imgix.cosmicjs.com/c1ef2880-4045-11ec-9580-ebf669758fed-Switch4.png",
    title: "Step 4 Professional Accounts are Free",
  },
  {
    image:
      "https://imgix.cosmicjs.com/c1f4f4e0-4045-11ec-9580-ebf669758fed-Switch5.png",
    title: "Step 5 Select a Category",
  },
  {
    image:
      "https://imgix.cosmicjs.com/c2065a00-4045-11ec-9580-ebf669758fed-Switch6.png",
    title: "Step 6 Select Business - IMPORTANT!",
  },
  {
    image:
      "https://imgix.cosmicjs.com/c21722e0-4045-11ec-9580-ebf669758fed-Switch7.png",
    title: "Step 7 Switch to Professional",
  },
  {
    image:
      "https://imgix.cosmicjs.com/c1d33c10-4045-11ec-9580-ebf669758fed-Switch8.png",
    title: `Step 8 Connect to Facebook. DON'T SKIP!`,
  },
  {
    image:
      "https://imgix.cosmicjs.com/c2163880-4045-11ec-9580-ebf669758fed-Switch9.png",
    title: "Step 9 Sign in to Facebook",
  },
  {
    image:
      "https://imgix.cosmicjs.com/c2179810-4045-11ec-9580-ebf669758fed-Switch10.png",
    title: "Step 10 Continue as you!",
  },
  {
    image:
      "https://imgix.cosmicjs.com/c1b72890-4045-11ec-9580-ebf669758fed-Switch11.png",
    title: "Step 11 Set up Accounts Center and Continue",
  },
  {
    image:
      "https://imgix.cosmicjs.com/c2135250-4045-11ec-9580-ebf669758fed-Switch12.png",
    title: "Step 12 If already used, remove.",
  },
  {
    image:
      "https://imgix.cosmicjs.com/c2183450-4045-11ec-9580-ebf669758fed-Switch13.png",
    title: "Step 13 Set up your accounts center",
  },
  {
    image:
      "https://imgix.cosmicjs.com/c214d8f0-4045-11ec-9580-ebf669758fed-Switch14.png",
    title: `Step 14 Connect/Create your Facebook Page. DON'T SKIP!`,
  },
  {
    image:
      "https://imgix.cosmicjs.com/c2006690-4045-11ec-9580-ebf669758fed-Switch15.png",
    title: "Step 15 Create Facebook Page.",
  },
];

const SwitchAccount = ({ path = null }) => {
  const currentAccount = useSelector((state) =>
    selectCurrentInstagramBasicAccount(state)
  ) as InstagramBasicAccounts | null;
  const loading = useSelector((state) =>
    selectUserInfoLoading(state)
  ) as boolean;
  const [hasWindow, setHasWindow] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentAttempt, setCurrentAttempt] = useState(-1);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setHasWindow(true);
    }
  }, []);

  useEffect(() => {
    if (currentAccount) {
      if (currentAccount.account_type === AccountTypes.BUSINESS) {
        // SUCCESS YOU HAVE A BUSINESS
        navigate("/dashboard/getting-started/connected/", {
          state: { navigationPath: path },
        });
      } else {
        setCurrentAttempt((value) => value + 1);
      }
    }
  }, [currentAccount]);

  const handleCarouselChange = (value) => {
    setCurrentSlide(value);
  };

  const getSlides = () => {
    if (
      currentAccount &&
      currentAccount.account_type === AccountTypes.MEDIA_CREATOR
    ) {
      return SwitchCarousel.map((obj, index) => (
        <div
          className="card-panel flex flex-col justify-center items-center mx-auto w-full"
          key={`how-to-switch-step-${index}`}
        >
          <p>{obj.title}</p>
          <Imgix
            src={`${obj.image}?auto=format`}
            width={310}
            height={531}
            htmlAttributes={{
              alt: `How to Switch Step ${index + 1}`,
              src: `${obj.image}?auto=format`,
            }}
            attributeConfig={{
              src: "data-src",
              srcSet: "data-srcset",
              sizes: "data-sizes",
            }}
          />
        </div>
      ));
    }
    return PersonalCarousel.map((obj, index) => (
      <div
        className="card-panel flex flex-col justify-center items-center mx-auto w-full"
        key={`how-to-switch-personal-step-${index}`}
      >
        <p>{obj.title}</p>
        <Imgix
          src={`${obj.image}?auto=format`}
          width={310}
          height={531}
          htmlAttributes={{
            alt: `How to Switch Step Personal ${index + 1}`,
            src: `${obj.image}?auto=format`,
          }}
          attributeConfig={{
            src: "data-src",
            srcSet: "data-srcset",
            sizes: "data-sizes",
          }}
        />
      </div>
    ));
  };

  const getSlideCount = () => {
    if (
      currentAccount &&
      currentAccount.account_type === AccountTypes.MEDIA_CREATOR
    ) {
      return SwitchCarousel.length;
    }
    return PersonalCarousel.length;
  };

  return (
    <>
      <div
        className={cn([
          styles.howItWorksOnboardingContainer,
          styles.switchAccountContainer,
          styles.defaultContainer,
        ])}
      >
        {/* <Timeline selectedDot={1} /> */}
        <h3>{currentAttempt <= 0 ? `It's Easy!` : `Oops, Let's Try Again!`}</h3>
        <h2>
          {currentAttempt <= 0
            ? `How to Switch to an Instagram Creator or Business Account`
            : `You are not set up as a Business Account`}
        </h2>
        <div className={styles.modalContainer}>
          {hasWindow && (
            <div className={styles.carouselContainer}>
              <Flicking
                align="center"
                circular={false}
                defaultIndex={currentSlide}
                onChanged={(e: ChangedEvent) => handleCarouselChange(e.index)}
              >
                {getSlides()}
              </Flicking>
              <Dots
                value={currentSlide}
                onChange={(value) => handleCarouselChange(value)}
                number={getSlideCount()}
              />
            </div>
          )}
        </div>

        <RoundedButton
          extraClass={styles.extendButton}
          onClick={() => {}}
          disabled={false}
          id="submit"
        >
          {!loading &&
            (currentAttempt <= 0 && isMobile.any()
              ? "Verify Account"
              : "Verify Account")}
          {loading && <LoadingSmall />}
        </RoundedButton>
        <button type="button" className={styles.learnHow}>
          How to Switch an Account?
        </button>
        {!isMobile.any() && (
          <p
            style={{ fontSize: "12px" }}
            className="text-bidddyAltGray mx-auto font-inter text-center"
          >
            <strong>Please Note:</strong> In order to switch to a professional
            account please do it through the Instagram mobile app.
          </p>
        )}
        {currentAccount !== null && (
          <a
            className={styles.link}
            href={
              isMobile.any()
                ? `instagram://user?username=${currentAccount.username}`
                : `https://www.instagram.com/accounts/edit/`
            }
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            Login to Instagram
          </a>
        )}
      </div>
    </>
  );
};

export default SwitchAccount;
